import React from 'react';
import Proyects from './Proyects';

const Home = () => {

    return(
        <section id="Home">
            <Proyects />
        </section>        
    )
    
}

export default Home;