import cover_Clotilde from './assets/images/covers/Cover_Clotilde.jpg';
import thumb_Clotilde from './assets/images/works/01_Clotilde/Thumb/Clotilde_fest.jpg';
import img1_Clotilde from './assets/images/works/01_Clotilde/Clotilde_fest_1.jpg';
import img2_Clotilde from './assets/images/works/01_Clotilde/Clotilde_fest_2.gif';
import img3_Clotilde from './assets/images/works/01_Clotilde/Clotilde_fest_3.jpg';
import img4_Clotilde from './assets/images/works/01_Clotilde/Clotilde_fest_4.jpg';
import img5_Clotilde from './assets/images/works/01_Clotilde/Clotilde_fest_5.jpg';
import img6_Clotilde from './assets/images/works/01_Clotilde/Clotilde_fest_6.jpg';
import video_Clotilde from './assets/images/works/01_Clotilde/Clotilde_fest.mp4';

import cover_Futurama from './assets/images/covers/Cover_Que_sera.jpg';
import thumb_Futurama from './assets/images/works/02_Que_sera/Thumb/Que_sera.jpg';
import video_Futurama from './assets/images/works/02_Que_sera/Que_sera_manana.mp4';

import cover_Slime from './assets/images/covers/Cover_Slime.jpg';
import thumb_Slime from './assets/images/works/03_Slimer/Thumb/Video1.jpg';
import img1_Slime from './assets/images/works/03_Slimer/Slimer_Master.jpg';
import img2_Slime from './assets/images/works/03_Slimer/Slimer_TresCuartos.jpg';
import video_Slime from './assets/images/works/03_Slimer/Slimer_TurnAround.mp4';

import cover_UGT from './assets/images/covers/Cover_UGT.jpg';
import thumb_UGT from './assets/images/works/04_UGT/Thumb/UGT_Huelga_de_la_Canadiense.jpg';
import img1_UGT from './assets/images/works/04_UGT/Ugt_Huelga_Canadiense_1.jpg';
import img2_UGT from './assets/images/works/04_UGT/Ugt_Huelga_Canadiense_2.jpg';
import video_UGT from './assets/images/works/04_UGT/UGT_Huelga_de_la_Canadiense.mp4';

import cover_Mataro from './assets/images/covers/Cover_Mataro.jpg';
import thumb_Mataro from './assets/images/works/05_Mataro_motion/Thumb/Video1.jpg';
import img1_Mataro from './assets/images/works/05_Mataro_motion/Capt1.jpg';
import img2_Mataro from './assets/images/works/05_Mataro_motion/Capt2.jpg';
import img3_Mataro from './assets/images/works/05_Mataro_motion/Capt3.jpg';
import video_Mataro from './assets/images/works/05_Mataro_motion/Mataro_Motion.mp4';

import cover_36Days from './assets/images/covers/Cover_36Days.jpg';
import img1_36Days from './assets/images/works/06_36Days/36Days_Full.jpg';
import img2_36Days from './assets/images/works/06_36Days/Pack1.jpg';
import img3_36Days from './assets/images/works/06_36Days/Pack2.jpg';
import img4_36Days from './assets/images/works/06_36Days/Pack3.jpg';

import cover_Crash from './assets/images/covers/Cover_Crash.jpg';
import thumb_Crash from './assets/images/works/07_Crash/Thumb/Video1.jpg';
import img1_Crash from './assets/images/works/07_Crash/Crash_master.jpg';
import img2_Crash from './assets/images/works/07_Crash/Crash_detail.jpg';
import video_Crash from './assets/images/works/07_Crash/Crash1.mp4';

import cover_Conecta2 from './assets/images/covers/Cover_Conecta2.jpg';
import thumb_Conecta2 from './assets/images/works/08_Conecta2/Thumb/Video1.jpg';
import img1_Conecta2 from './assets/images/works/08_Conecta2/Conecta2_pruebas.jpg';
import img2_Conecta2 from './assets/images/works/08_Conecta2/Conecta2.jpg';
import video_Conecta2 from './assets/images/works/08_Conecta2/Conecta2_video.mp4';

import cover_TRex from './assets/images/covers/Cover_TRex.jpg';
import thumb_TRex from './assets/images/works/09_TRex/Thumb/Video1.jpg';
import img1_TRex from './assets/images/works/09_TRex/Details.jpg';
import img2_TRex from './assets/images/works/09_TRex/Turntable.jpg';
import img3_TRex from './assets/images/works/09_TRex/Fragmento.jpg';
import video_TRex from './assets/images/works/09_TRex/Turn_Around_TREX.mp4';

import cover_Nadal_Bcn from './assets/images/covers/Cover_Nadal.jpg';
import thumb_Nadal_Bcn from './assets/images/works/10_Nadal_Bcn/Thumb/Video1.jpg';
import video_Nadal_Bcn from './assets/images/works/10_Nadal_Bcn/Video_1.mp4';
import thumb2_Nadal_Bcn from './assets/images/works/10_Nadal_Bcn/Thumb/Video2.jpg';
import video2_Nadal_Bcn from './assets/images/works/10_Nadal_Bcn/Video_2.mp4';
import thumb3_Nadal_Bcn from './assets/images/works/10_Nadal_Bcn/Thumb/Video3.jpg';
import video3_Nadal_Bcn from './assets/images/works/10_Nadal_Bcn/Video_3.mp4';

import cover_ResiduZero from './assets/images/covers/Cover_Recollida.jpg';
import thumb_ResiduZero from './assets/images/works/11_Residu_Zero/Thumb/Video1.jpg';
import img1_ResiduZero from './assets/images/works/11_Residu_Zero/Chica_Sprites.jpg';
import img2_ResiduZero from './assets/images/works/11_Residu_Zero/Fondo.jpg';
import video_ResiduZero from './assets/images/works/11_Residu_Zero/Residu_Zero.mp4';

import cover_UrbanStyle from './assets/images/covers/Cover_Personal.jpg';
import img1_UrbanStyle from './assets/images/works/12_UrbanStyle/Predy_Wildstyle.jpg';
import img2_UrbanStyle from './assets/images/works/12_UrbanStyle/OldSchool.jpg';
import img3_UrbanStyle from './assets/images/works/12_UrbanStyle/Ninja_Tourtles.jpg';
import img4_UrbanStyle from './assets/images/works/12_UrbanStyle/PredyMusic.jpg';
import img5_UrbanStyle from './assets/images/works/12_UrbanStyle/PredyWater.jpg';
import img6_UrbanStyle from './assets/images/works/12_UrbanStyle/Wacom.jpg';

import cover_Nadal_Mataro from './assets/images/covers/Cover_Nadal_Mataro.jpg';
import thumb_Nadal_Mataro from './assets/images/works/13_Nadal_Mataro/Thumb/Video1.jpg';
import video_Nadal_Mataro from './assets/images/works/13_Nadal_Mataro/00_Caganer.mp4';
import thumb2_Nadal_Mataro from './assets/images/works/13_Nadal_Mataro/Thumb/Video2.jpg';
import video2_Nadal_Mataro from './assets/images/works/13_Nadal_Mataro/01_Jersey.mp4';
import thumb3_Nadal_Mataro from './assets/images/works/13_Nadal_Mataro/Thumb/Video3.jpg';
import video3_Nadal_Mataro from './assets/images/works/13_Nadal_Mataro/02_Galets.mp4';
import thumb4_Nadal_Mataro from './assets/images/works/13_Nadal_Mataro/Thumb/Video4.jpg';
import video4_Nadal_Mataro from './assets/images/works/13_Nadal_Mataro/03_Cabalgata.mp4';

import cover_Benestar_Animal from './assets/images/covers/Cover_Benestar_animal.jpg';
import img1_Benestar_Animal from './assets/images/works/14_Benestar_Animal/Mock_ups.jpg';
import thumb_Benestar_Animal from './assets/images/works/14_Benestar_Animal/Thumb/Video1.jpg';
import video_Benestar_Animal from './assets/images/works/14_Benestar_Animal/Video_1.mp4';
import thumb2_Benestar_Animal from './assets/images/works/14_Benestar_Animal/Thumb/Video2.jpg';
import video2_Benestar_Animal from './assets/images/works/14_Benestar_Animal/Video_2.mp4';
import thumb3_Benestar_Animal from './assets/images/works/14_Benestar_Animal/Thumb/Video3.jpg';
import video3_Benestar_Animal from './assets/images/works/14_Benestar_Animal/Video_3.mp4';

import cover_ATC from './assets/images/covers/Cover_ATC.jpg';
import thumb_ATC from './assets/images/works/15_ATC/Thumb/ATC.jpg';
import img1_ATC from './assets/images/works/15_ATC/ATC_Storyboard.jpg';
import img2_ATC from './assets/images/works/15_ATC/ATC_Bus.jpg';
import img3_ATC from './assets/images/works/15_ATC/ATC_Revista.jpg';
import video_ATC from './assets/images/works/15_ATC/ATC_video.mp4';

import cover_Acolliment_Familiar from './assets/images/covers/Cover_Acolliment.jpg';
import img1_Acolliment_Familiar from './assets/images/works/16_Acolliment_Familiar/Tetris_grafica.jpg';
import img2_Acolliment_Familiar from './assets/images/works/16_Acolliment_Familiar/Tetris_3D1.jpg';
import img3_Acolliment_Familiar from './assets/images/works/16_Acolliment_Familiar/Tetris_MakingOff.jpg';
import img4_Acolliment_Familiar from './assets/images/works/16_Acolliment_Familiar/Tetris_3D2.jpg';


export default {
    path: 'src/assets/images/covers/',
    works: [
        {
          'id': '1',
          'cover': 'Cover_Clotilde.jpg',
          'path': cover_Clotilde,
          'urlName': 'Clotilde',
          'name': 'Clotilde festival',
          'client': 'Generalitat de Catalunya',
          'thumb' : thumb_Clotilde,
          'img1': img1_Clotilde,
          'img2': img2_Clotilde,
          'img3': img3_Clotilde,
          'img4': img4_Clotilde,
          'img5': img5_Clotilde,
          'img6': img6_Clotilde,
          'video': video_Clotilde,
          'description': 'El Palau Robert necesitaba crear una imagen de marca para su nuevo festival de música irreverente y actual, el Clotilde Fest. Dentro del programa Palau Joven, este festival se basa en el activismo de género, con mucho Swag y sin miedo de salir de la zona de confort.',
          'description2': 'Como Clotilde Cerdà, quien hace más de 100 años, sin pedir permiso ni perdón, dejó todas sus comodidades para hacer lo que le daba la gana: música y feminismo trasatlántico.',
          'description3': 'Así que, con la ayuda del artista 3D Diego Gómez, se creó Clotilde, que junto con la tipografía flexible y moderna se convierte en un símbolo de los valores del festival y refleja su espíritu innovador e inconformista.',
        },
        {
          'id': '2',
          'cover': 'Cover_Que_sera.jpg',
          'path': cover_Futurama,
          'urlName': 'Futurama',
          'name': '¿Qué será mañana?',
          'client': 'Personal',
          'thumb' : thumb_Futurama,
          'video' : video_Futurama,
          'description': 'Este proyecto nace con la intención de hacer un videoclip con temática futurista, y haciendo uso de la ilustración, acercamos toda la estética de Futurama para dar vida a este clip de rap en Español.',
          'description2': 'La canción tiene el titulo de "¿Qué será Mañana?" y está disponible desde mi canal de Youtube.',
        },
        {
          'id': '3',
          'cover': 'Cover_Slime.jpg',
          'path': cover_Slime,
          'urlName': 'Slimer',
          'name': 'Slimer',
          'client': 'Personal',
          'thumb' : thumb_Slime,
          'img1' : img1_Slime,
          'img2' : img2_Slime, 
          'video': video_Slime,
          'description': 'Un día me levanté por una pesadilla en la que soñé que iba por la calle y todos los elementos se derretían, los bancos, las farolas, los coches...',
          'description2': 'Tras pasar el mal rato, me tomé un café y decidí plasmar esa estética en un diseño, y de ahí sale \'Slimer\' esta pieza que busca plasmar la esencia de ese sueño.',
        },
        {
          'id': '4',
          'cover': 'Cover_UGT.jpg',
          'path': cover_UGT,
          'urlName': 'Canalenca',
          'name': 'Huelga de La Canadiense',
          'client': 'UGT (100 años)',
          'thumb' : thumb_UGT,
          'img1' : img1_UGT,
          'img2' : img2_UGT,
          'video': video_UGT,
          'description': 'El año 2019 marca el 100 aniversario de la obtención de la jornada de ocho horas después de las protestas laborales en Barcelona, un evento de extrema importancia no sólo en Cataluña o España, sino en Europa y el mundo.', 
          'description2': 'En febrero de 1919, se inició la huelga contra la empresa eléctrica Riegos y Fuerzas del Ebro, perteneciente a Barcelona Traction, Light and Power Company, Limited, mejor conocida en España como ‘La Canadiense‘ o ‘La Canadenca‘, en catalán.',
          'description3': 'Al principio, se debió al despido de ocho oficinistas quienes habían sido hechos fijos pero con un salario menor, y quienes se negaron a trabajar bajo esas condiciones en una ciudad tan cara, mientras los empresarios catalanes se enriquecían desproporcionadamente con sus ventas durante la Primera Guerra Mundial.',
          'description4': 'Con motivo de este centenario, UGT nos pidió que hiciéramos un video animado para recordar tal evento, al final optamos por una estética muy minimalista para plasmar este concepto.',
        },
        {
          'id': '5',
          'cover': 'Cover_Mataro.jpg',
          'path': cover_Mataro,
          'urlName': 'Mataro',
          'name': 'Més serveis',
          'client': 'Ajuntament de Mataró',
          'thumb' : thumb_Mataro,
          'img1' : img1_Mataro,
          'img2' : img2_Mataro,
          'img3' : img3_Mataro,
          'video': video_Mataro,
          'description': 'El ayuntamiento de Mataró se pone en contacto con nosotros para hacer un comunicado a la población informando que han recibido una inyección de capital y van a destinar estos recursos ampliando las ayudas que ofrecen, más zonas verdes y más servicios para Mataró.',
          'description2': 'Para ello nos piden pequeñas cápsulas de Motion Graphics que implementarán en medios para comunicar que la ciudad está creciendo.',
        },
        {
          'id': '6',
          'cover': 'cover_36Days.jpg',
          'path': cover_36Days,
          'urlName': '36 Days',
          'name': '36 Days of Type',
          'client': 'Personal',
          'thumb' : '', 
          'img1' : img1_36Days,
          'img2' : img2_36Days,
          'img3' : img3_36Days,
          'img4' : img4_36Days,
          'video': '',
          'description': 'La fascinación que provoca la forma de las letras, verdadero vehículo comunicativo de nuestra era, da como resultado iniciativas como esta en las que deberemos reinterpretar gráficamente las letras y números de nuestro alfabeto en un maratón sin descanso.',
          'description2': '36 Days of Type es un proyecto que invita a diseñadores, ilustradores y artistas gráficos a que aporten su visión de las letras y números que forman nuestro alfabeto. Es un proyecto que pretende ser un espacio para la creación en torno a la tipografía y sus infinitas posibilidades gráficas.',
        },
        {
          'id': '7',
          'cover': 'Cover_Crash.jpg',
          'path': cover_Crash,
          'urlName': 'Crash',
          'name': 'Crash',
          'client': 'Personal',
          'thumb' : thumb_Crash,
          'img1' : img1_Crash,
          'img2' : img2_Crash,
          'video': video_Crash,
          'description': 'Romper, romper, romper, ¿Cómo quedan las cosas destruidas? Esta premisa es suficiente para comenzar este proyecto y buscar está estética en el mundo del lettering 3D.',
          'description2': 'Es un proyecto personal que nace como reto creativo, intentando utilizar la deconstrucción a favor del diseño.',
        },
        {
          'id': '8',
          'cover': 'Cover_Conecta2.jpg',
          'path': cover_Conecta2,
          'urlName': 'Conecta2',
          'name': 'Conecta2',
          'client': 'Personal',
          'thumb' : thumb_Conecta2,
          'img1' : img1_Conecta2,
          'img2' : img2_Conecta2,
          'video': video_Conecta2,
          'description': 'Surge una nueva canción en mi repertorio, bajo el nombre de Conecta2, para lanzar dicho tema decido hacer uso de un lyric video, en la que toda la letra de la canción va apareciendo a modo Karaoke.',
          'description2': 'Para introducir esa idea creo esta animación de video utilizando la técnica de Motion Graphics.',
        },
        {
          'id': '9',
          'cover': 'Cover_TRex.jpg',
          'path': cover_TRex,
          'urlName': 'TRex',
          'name': 'Proyecto T-Rex',
          'client': 'Personal',
          'thumb' : thumb_TRex,
          'img1' : img1_TRex,
          'img2' : img2_TRex,          
          'img3' : img3_TRex,
          'video': video_TRex,
          'description': '¿Hasta dónde puedo llevar el modelado 3D?, buscando el máximo realismo en el modelado 3D trato de juntar dos de mis pasiones, el 3D y los dinosaurios.',
          'description2': 'Para este proyecto hice uso del software ZBrush para conseguir un nivel detalle mucho mayor en las formas y emplee 4 meses para desarrollar completamente el proyecto.',
        },
        {
          'id': '10',
          'cover': 'NadalBcn.jpg',
          'path': cover_Nadal_Bcn,
          'urlName': 'NadalBcn',
          'name': 'Viu el teu nadal',
          'client': 'Ajuntament de Barcelona',
          'thumb' : thumb_Nadal_Bcn,
          'video' : video_Nadal_Bcn,
          'thumb2' : thumb2_Nadal_Bcn,
          'video2' : video2_Nadal_Bcn,
          'thumb3' : thumb3_Nadal_Bcn,
          'video3' : video3_Nadal_Bcn,
          'description': 'La navidad llega a Horta-Guinardó con un montón de actividades para todas las edades. Cabalgatas de Papá Noel y los Reyes magos, pesebres, villancicos, talleres infantiles, espectáculos de caga tió, exposiciones, etc. Todos pueden participar en alguna de las celebraciones que habrá hasta el 7 de Enero.',
          'description2': 'Para fomentar esta campaña, se realizan una serie de gráficas y videos que se muestran por toda Barcelona, mediante OPIS, los paneles del metro, farolas, etc.',
        },
        {
          'id': '11',
          'cover': 'Cover_Residu_Zero.jpg',
          'path': cover_ResiduZero,
          'urlName': 'ResiduZero',
          'name': 'Residu zero',
          'client': 'Ajuntament de Barcelona',
          'thumb' : thumb_ResiduZero,
          'img1' : img1_ResiduZero,
          'img2' : img2_ResiduZero,
          'video': video_ResiduZero,
          'description': 'La estrategia de \'residu zero\' está basada en la prevención, la reducción de la generación de residuos, la reutilización y la recogida selectiva, poniendo especial atención en la materia orgánica.',
          'description2': 'Para ello desarrollamos una cápsula para usar en redes sociales, con una estética de videojuegos de 8bits, para acercar al público esta campaña y conseguir concienciar a los más jóvenes.',
        },
        {
          'id': '12',
          'cover': 'cover_UrbanStyle.jpg',
          'path': cover_UrbanStyle,
          'urlName': 'Urban_Style',
          'name': 'Urban style',
          'client': 'Personal',
          'thumb' : '', 
          'img1' : img1_UrbanStyle,
          'img2' : img2_UrbanStyle,
          'img3' : img3_UrbanStyle,
          'img4' : img4_UrbanStyle,
          'img5' : img5_UrbanStyle,
          'img6' : img6_UrbanStyle,
          'video': '',
          'description': 'El arte está en la calle, este fue mi primer estímulo creativo, el interés por el arte urbano, el graffiti y las formas que se alejan mucho del lettering convencional o de imprenta.',
          'description2': 'Como evolución a esta etapa, he creado estas ilustraciones de carácter personal en las que trato plasmar toda esta estética urbana a un formato mucho más digital y limpio.',
        },
        {
          'id': '13',
          'cover': 'NadalMataro.jpg',
          'path': cover_Nadal_Mataro,
          'urlName': 'NadalMataro',
          'name': 'Nadal a Mataró',
          'client': 'Ajuntament de Mataró',
          'thumb' : thumb_Nadal_Mataro,
          'video' : video_Nadal_Mataro,
          'thumb2' : thumb2_Nadal_Mataro,
          'video2' : video2_Nadal_Mataro,
          'thumb3' : thumb3_Nadal_Mataro,
          'video3' : video3_Nadal_Mataro,
          'thumb4' : thumb4_Nadal_Mataro,
          'video4' : video4_Nadal_Mataro,
          'description': 'El ayuntamiento de Mataró nos encargó su campaña navideña. Y esta vez, hemos resuelto el pedido con algo divertido e incluso atrevido.',
          'description2': 'Farolillos, galets y un tió fueron algunos de los elementos encargados de recordarnos que, en navidad, algunas cosas es mejor que nunca cambien aunque sean jerséis de los que pican- para que vivamos una fiestas realmente auténticas.',
          'description3': 'Una campaña muy cercana que, durante unos días muy especiales, hizo que el espíritu de la navidad se viviese en Mataró más que nunca.',
        },
        {
          'id': '14',
          'cover': 'Benestar_Animal.jpg',
          'path': cover_Benestar_Animal,
          'urlName': 'BenestarAnimal',
          'name': 'Benestar animal',
          'client': 'Ajuntament de Barcelona',
          'img1' : img1_Benestar_Animal,
          'thumb' : thumb_Benestar_Animal,
          'video' : video_Benestar_Animal,
          'thumb2' : thumb2_Benestar_Animal,
          'video2' : video2_Benestar_Animal,
          'thumb3' : thumb3_Benestar_Animal,
          'video3' : video3_Benestar_Animal,
          'description': 'En Barcelona son muchos los hogares en los que vive algún animal doméstico y muchas las personas implicadas en su cuidado y atención, pero aún son muchos los animales de compañía que cada año se encuentran perdidos o abandonados y que necesitan atención y protección. Por ello, la convivencia, la defensa y la protección de los animales es una carrera de fondo.',
          'description2': 'Para concienciar de todo esto realizamos esta campaña en la que a base de un ritmo de animación picado y saltos de posición de los elementos, conseguimos una estética que recuerda al celuloide, a como en antaño se cargaban las películas.',
        },

        {
          'id': '15',
          'cover': 'Cover_ATC.jpg',
          'path': cover_ATC,
          'urlName': 'ATC',
          'name': 'Més a prop, més enllà',
          'client': 'Agència Tributària de Catalunya',
          'thumb' : thumb_ATC,
          'img1' : img1_ATC,
          'img2' : img2_ATC,
          'img3' : img3_ATC,
          'video': video_ATC,
          'description': 'Como comunicado la Generalitat de Cataluña, entran en funcionamiento las nuevas oficinas y puntos de asistencia de la ATC. En concreto, se añadirán 15 oficinas territoriales y 13 compartidas que, junto con las ya existentes, sumarán un total de 32 oficinas que se complementarán con 161 puntos de información desplegados por todo el territorio.',
          'description2': 'Sus principales funciones serán la gestión de expedientes tributarios, la cita previa, la autoliquidación de tributos, el registro de documentación y la información sobre tributos que gestiona la ATC y otros entes locales y supramunicipales.',
          'description3': 'Para ello usamos la técnica de Motion graphics para mostrar toda esta información de una forma más dinámica y cercana a la población.',
        },

        {
          'id': '16',
          'cover': 'cover_Acolliment_Familiar.jpg',
          'path': cover_Acolliment_Familiar,
          'urlName': 'Acolliment_Familiar',
          'name': 'Acolliment familiar',
          'client': 'Generalitat de Catalunya',
          'thumb' : '', 
          'img1' : img1_Acolliment_Familiar,
          'img2' : img2_Acolliment_Familiar,
          'img3' : img3_Acolliment_Familiar,
          'img4' : img4_Acolliment_Familiar,
          'description': 'En Catalunya hay más de 100 niños que nos necesitaban, a la Generalitat de Catalunya, a nosotros y a muchas familias dispuestas a compartir todo su amor.',
          'description2': 'Así que nos estrujamos el coco para hacer una campaña que, además de bonita, fuese capaz de hacer feliz a todos estos niños. ¿Lo conseguiremos? Estamos seguros de que sí.',
        },

        
        
        
    ]
}